var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.title || _vm.links.length)?_c('div',{staticClass:"c-link-list-layout"},[(_vm.title)?_c('div',{staticClass:"px-lg py-md >=1024:px-3xl >=1024:py-lg bg-background"},[_c('BaseH2',{staticClass:"text-h4 font-bold mb-2",domProps:{"textContent":_vm._s(_vm.title)}})],1):_vm._e(),_vm._v(" "),(_vm.links)?_c('div',{class:[
			'px-lg py-md >=1024:px-3xl >=1024:py-lg',
			'bg-background mt-2 space-y-lg',
		]},_vm._l((_vm.computedLinks),function(link,index){return _c('NuxtLinkExt',{key:`link-${index}`,class:[
				'c-link-list-layout__item group',
				'relative flex justify-between items-center',
			],attrs:{"to":link.url,"download":link.type === 'media' ? link.name : false,"target":link.type === 'media' ? '_blank' : link.target}},[_c('div',{class:[
					'c-link-list-layout__item-backdrop',
					'absolute -left-16 -right-8 -top-8 -bottom-8',
					'rounded-full overflow-hidden',
					'duration-500 ease-smooth-out',
					'group-hover:bg-black group-hover:bg-opacity-10',
				]}),_vm._v(" "),_c('div',{class:[
					'flex flex-col items-start',
					'>=1024:flex-row >=1024:items-center >=1024:space-x-12',
					'mb-4 duration-500 ease-smooth-out pr-24',
					'transform translate-x-0 group-hover:translate-x-8',
				]},[_c('span',{staticClass:"font-darker-grotesque font-medium text-button",domProps:{"textContent":_vm._s(link.name)}}),_vm._v(" "),_c('div',{class:[
						'flex gap-x-8 flex-shrink-0',
						'font-darker-grotesque font-bold uppercase',
						'text-label-file mt-8 >=1024:mt-4',
					]},[(link.type === 'media')?_c('div',{class:[
							'rounded-full border-2 border-black',
							'px-8 pt-3 pb-6',
						],domProps:{"textContent":_vm._s(`.${link.extension}`)}}):_vm._e(),_vm._v(" "),(link.type === 'media')?_c('div',{class:[
							'rounded-full border-2 border-black',
							'px-8 pt-3 pb-6',
						],domProps:{"textContent":_vm._s(link.size)}}):_vm._e()])]),_vm._v(" "),_c('div',{class:[
					'flex justify-center items-center flex-shrink-0',
					'w-32 h-32 rounded-full bg-black text-white',
				]},[(link.type === 'media')?_c('SvgDownload',{staticClass:"w-16 -mt-1"}):_c('SvgArrow',{class:[
						'w-16',
						{ 'transform -rotate-45 mt-1': link.isExternal },
					]})],1)])}),1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }