<template>
	<div v-if="title || links.length" class="c-link-list-layout">
		<div
			v-if="title"
			class="px-lg py-md >=1024:px-3xl >=1024:py-lg bg-background"
		>
			<BaseH2 class="text-h4 font-bold mb-2" v-text="title" />
		</div>

		<div
			v-if="links"
			:class="[
				'px-lg py-md >=1024:px-3xl >=1024:py-lg',
				'bg-background mt-2 space-y-lg',
			]"
		>
			<NuxtLinkExt
				v-for="(link, index) in computedLinks"
				:key="`link-${index}`"
				:to="link.url"
				:class="[
					'c-link-list-layout__item group',
					'relative flex justify-between items-center',
				]"
				:download="link.type === 'media' ? link.name : false"
				:target="link.type === 'media' ? '_blank' : link.target"
			>
				<!-- Backdrop -->
				<div
					:class="[
						'c-link-list-layout__item-backdrop',
						'absolute -left-16 -right-8 -top-8 -bottom-8',
						'rounded-full overflow-hidden',
						'duration-500 ease-smooth-out',
						'group-hover:bg-black group-hover:bg-opacity-10',
					]"
				></div>

				<!-- Content -->
				<div
					:class="[
						'flex flex-col items-start',
						'>=1024:flex-row >=1024:items-center >=1024:space-x-12',
						'mb-4 duration-500 ease-smooth-out pr-24',
						'transform translate-x-0 group-hover:translate-x-8',
					]"
				>
					<span
						class="font-darker-grotesque font-medium text-button"
						v-text="link.name"
					></span>

					<!-- Labels -->
					<div
						:class="[
							'flex gap-x-8 flex-shrink-0',
							'font-darker-grotesque font-bold uppercase',
							'text-label-file mt-8 >=1024:mt-4',
						]"
					>
						<div
							v-if="link.type === 'media'"
							:class="[
								'rounded-full border-2 border-black',
								'px-8 pt-3 pb-6',
							]"
							v-text="`.${link.extension}`"
						></div>

						<div
							v-if="link.type === 'media'"
							:class="[
								'rounded-full border-2 border-black',
								'px-8 pt-3 pb-6',
							]"
							v-text="link.size"
						></div>
					</div>
				</div>

				<!-- Icons -->
				<div
					:class="[
						'flex justify-center items-center flex-shrink-0',
						'w-32 h-32 rounded-full bg-black text-white',
					]"
				>
					<SvgDownload
						v-if="link.type === 'media'"
						class="w-16 -mt-1"
					/>
					<SvgArrow
						v-else
						:class="[
							'w-16',
							{ 'transform -rotate-45 mt-1': link.isExternal },
						]"
					/>
				</div>
			</NuxtLinkExt>
		</div>
	</div>
</template>

<script>
import SvgArrow from '~/assets/svgs/icon-arrow.svg?inline';
import SvgDownload from '~/assets/svgs/icon-download.svg?inline';

export default {
	name: 'LinkListLayout',
	components: { SvgArrow, SvgDownload },
	inheritAttrs: false,

	props: {
		title: String,
		links: Array,
	},

	computed: {
		computedLinks() {
			return this.links
				?.filter((e) => e && e.url)
				.map((link) => ({
					...link,
					isExternal: this.isExternal(link.url),
					size: this.formatSize(link.size),
				}));
		},
	},

	methods: {
		isExternal(url) {
			if (url) {
				if (
					['http://', 'https://', 'ftp://'].some(
						(str) => url.indexOf(str) === 0
					)
				) {
					return true;
				}
				const splitOut = url.split('/');
				if (splitOut[0].indexOf('.') >= 0) {
					return true;
				}
			}

			return false;
		},

		formatSize(bytes) {
			if (bytes) {
				const sizes = {
					GB: Math.pow(1000, 3),
					MB: Math.pow(1000, 2),
					KB: 1000,
				};

				const keys = Object.keys(sizes);
				for (let i = 0; i < keys.length; i++) {
					const response = bytes / sizes[keys[i]];

					if (response >= 1 || i === keys.length - 1) {
						return `${response.toFixed(2)} ${keys[i]}`;
					}
				}
			}

			return false;
		},
	},
};
</script>

<style lang="postcss">
.c-link-list-layout__item > * {
	z-index: 1;
}
.c-link-list-layout__item-backdrop {
	z-index: 0;
}
.c-link-list-layout__item-backdrop:before {
	@apply w-full h-full rounded-full bg-primary;
	@apply absolute top-0 left-0 transform -translate-x-full;
	@apply duration-500 ease-smooth-out;
	content: '';
}
.c-link-list-layout__item:hover .c-link-list-layout__item-backdrop:before {
	@apply translate-x-0;
}
</style>
